import React, { useState, useEffect, useCallback, useRef } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Upload, Modal, Button, Tooltip, Progress } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const type = "DragableUploadList";

const DragableUploadListItem = ({
  originNode,
  moveRow,
  file,
  fileList,
  remove,
  source,
}) => {
  const ref = React.useRef();
  const index = fileList.indexOf(file);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  const errorNode = (
    <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
  );
  console.log(originNode, file, "djvlkzjj");
  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${
        isOver ? dropClassName : ""
      }`}
      style={{ cursor: "move" }}
    >
      {file.status === "error" ? (
        errorNode
      ) : source === "video" ? (
        <div style={{ height: 200, width: 200, position: "relative" }}>
          <div
            style={{
              borderRadius: "50%",
              backgroundColor: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              padding: 4,
              right: -12,
              top: -6,
              zIndex: 400,
              cursor: "pointer",
            }}
            onClick={() => {
              remove();
            }}
          >
            <CloseOutlined style={{ color: "white" }} />
          </div>
          {((file.percent && file.percent != 100) ||
            file.status == "uploading") && (
            <div
              style={{
                height: 200,
                width: 200,
                border: "1px solid #d9d9d9",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <p>uploading</p>
            </div>
          )}
          {((file.response?.url && file.percent == 100) || file.url) && (
            <video height="200" width="200" controls>
              <source src={file.url ?? file?.response?.url} />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      ) : (
        originNode
      )}
    </div>
  );
};

export default function SectionUpload(props) {
  const fileList = props.imageList;
  const handleChange = props.handleChange;
  const handleRemoveChange = props.handleRemoveChange;

  const handleImageDnd = props.handleImageDnd;

  const uploadTitle = props.uploadTitle ?? "Upload Images";
  const source = props.source ?? "image";

  console.log("CLOG::SectionUpload", fileList);

  const [previewImageProps, setImageProps] = useState({
    previewImage: "",
    previewVisible: false,
    previewTitle: "",
  });

  const handleCancel = () => {
    setImageProps({
      previewImage: "",
      previewVisible: false,
      previewTitle: "",
    });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setImageProps({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      // console.log(dragIndex, hoverIndex);
      // const dragRow = fileList[dragIndex];

      // setFileList(
      //   update(fileList, {
      //     $splice: [
      //       [dragIndex, 1],
      //       [hoverIndex, 0, dragRow],
      //     ],
      //   }),
      // );

      handleImageDnd(dragIndex, hoverIndex);
    },
    [fileList]
  );

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{uploadTitle}</div>
    </div>
  );
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Upload
          action={
            source === "video"
              ? `${process.env.REACT_APP_API_MEDIA_URL}_v2`
              : `${process.env.REACT_APP_API_MEDIA_URL}`
          }
          name="file"
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          multiple={true}
          itemRender={(originNode, file, currFileList, { remove }) => (
            <DragableUploadListItem
              originNode={originNode}
              file={file}
              fileList={currFileList}
              moveRow={moveRow}
              source={source}
              remove={remove}
            />
          )}
          onRemove={(file) => {
            console.log(file);

            handleRemoveChange(file);
          }}
        >
          {uploadButton}
        </Upload>
      </DndProvider>

      <Modal
        visible={previewImageProps.previewVisible}
        title={""}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{ width: "100%" }}
          src={previewImageProps.previewImage}
        />
      </Modal>
    </>
  );
}
